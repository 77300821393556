<script setup>
import { useShowProfileData } from "./business_logic";
import {
  fieldRequired,
  exactCharacterLength,
  validateIfNotEmpty,
  emailValid,
  minLength
} from "@/utils/input_validation.js";
import { examplePhoneNumber } from "@/utils/constants";
import {
  getYearsAgoAsYMD,
  addPostalCodeToPostalTown,
  stringNotEmpty,
} from "@/utils/functions";
import PhoneNumberInput from "@/components/inputs/PhoneNumberInput.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import Labelledfield from "@/components/utils/LabelledField";
import { computed, ref } from "vue";
import LoadingArea from "@/components/utils/LoadingArea.vue";
import {
  countyIsKirinyaga,
  userDataLoading,
  userProfile,
} from "@/utils/vue_helpers";

const {
  userProfileModels,
  supportingFormData,
  nameLabel,
  isIndividual,
  idOrBusinessText,
  isDisabled,
  updateUser,
  usingDefaultDateOfBirth,
  updateUserMutatation,
  errorItems,
} = useShowProfileData();

const primaryPhoneNumberInput = ref(null);
const secondaryPhoneNumberInput = ref(null);

const formRef = ref(null);
function onSubmitForm() {
  const formValid = formRef.value?.validate();
  if (formValid) {
    updateUser();
  }
}

const emailRules = computed(() => {
  const alreadyHasEmailAddress = stringNotEmpty(
    userProfile.value.email_address
  );
  const fieldIsRequired = !countyIsKirinyaga.value || alreadyHasEmailAddress;
  return [
    fieldIsRequired ? fieldRequired : () => true,
    validateIfNotEmpty([emailValid]),
  ];
});

const dateOfBirthLabel = computed(() => {
  if (isIndividual.value) {
    return "Date of Birth";
  }
  return "Date of Registration";
});
</script>

<template>
  <v-container fluid class="px-0 py-2">
    <v-row justify="center">
        <v-col cols="auto" class="text-h6 font-weight-bold primary--text">
            Profile Details
        </v-col>
    </v-row>

    <LoadingArea
      :loading="updateUserMutatation.isLoading.value || userDataLoading"
    >
      <v-form ref="formRef" @submit.prevent="onSubmitForm">
        <v-row>
          <!-- ID number -->
          <v-col cols="6">
            <Labelledfield :label="`${idOrBusinessText} Number`">
              <v-text-field
                outlined
                dense
                v-model="userProfileModels.idNumber"
                hide-details="auto"
                class="text-field-background"
                :disabled="true"
              />
            </Labelledfield>
          </v-col>

          <!-- Customer type -->
          <v-col cols="6">
            <Labelledfield label="Customer Type">
              <v-select
                :items="supportingFormData.customerTypes"
                item-text="name"
                item-value="id"
                outlined
                dense
                v-model="userProfileModels.customerTypeId"
                hide-details="auto"
                :disabled="true"
                class="text-field-background"
              ></v-select>
            </Labelledfield>
          </v-col>
        </v-row>
        <v-row>
          <!-- Name -->
          <v-col cols="6">
            <Labelledfield :label="nameLabel">
              <v-text-field
                outlined
                dense
                required
                v-model="userProfileModels.customerName"
                hide-details="auto"
                class="text-field-background"
                disabled
              />
            </Labelledfield>
          </v-col>

          <!-- Email -->
          <v-col cols="6">
            <Labelledfield label="Email Address" :required="!countyIsKirinyaga">
              <v-text-field
                outlined
                dense
                required
                v-model="userProfileModels.emailAddress"
                :rules="emailRules"
                hide-details="auto"
                class="text-field-background"
                :error-messages="errorItems.email_address.errorValue ?? []"
              />
            </Labelledfield>
          </v-col>
        </v-row>

        <v-row>
          <!-- Phone number 1 -->
          <v-col cols="6" class="">
            <Labelledfield
              :label="`Phone Number 1 (e.g. ${examplePhoneNumber})`"
              required
            >
              <PhoneNumberInput
                ref="primaryPhoneNumberInput"
                v-model="userProfileModels.phoneNumber1"
                :countryCode.sync="userProfileModels.phoneNumber1CountryCode"
                :rules="[fieldRequired, minLength(9)]"
                :error-messages="errorItems.phone_number1.errorValue ?? []"
              />
            </Labelledfield>
          </v-col>

          <!-- Phone number 2 -->
          <v-col cols="6">
            <Labelledfield
              :label="`Phone Number 2 (e.g. ${examplePhoneNumber})`"
            >
              <PhoneNumberInput
                ref="secondaryPhoneNumberInput"
                v-model="userProfileModels.phoneNumber2"
                :countryCode.sync="userProfileModels.phoneNumber2CountryCode"
                label="Phone Number 2"
                :rules="[validateIfNotEmpty([minLength(9)])]"
                :error-messages="errorItems.phone_number2.errorValue ?? []"
              />
            </Labelledfield>
          </v-col>
        </v-row>

        <v-row>
          <!-- Gender -->
          <v-col v-if="isIndividual" cols="6">
            <Labelledfield label="Gender">
              <v-select
                :items="supportingFormData.genders"
                :rules="[fieldRequired]"
                item-text="name"
                item-value="id"
                outlined
                dense
                v-model="userProfileModels.genderId"
                hide-details="auto"
                class="text-field-background"
              />
            </Labelledfield>
          </v-col>

          <!-- Date of birth -->
          <v-col cols="6">
            <Labelledfield :label="dateOfBirthLabel">
              <div class="d-flex">
                <DatePicker
                  :rules="[fieldRequired]"
                  :max="getYearsAgoAsYMD(18)"
                  :textFieldProps="{
                    outlined: true,
                    dense: true,
                    class: 'text-field-background',
                    'hide-details': 'auto',
                  }"
                  v-model="userProfileModels.dateOfBirth"
                />

                <v-tooltip v-if="usingDefaultDateOfBirth" top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      color="secondary"
                      v-on="on"
                      v-bind="attrs"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span>
                    This is the default Date of Birth, to change it, select a
                    new Date of Birth and click the update button below
                  </span>
                </v-tooltip>
              </div>
            </Labelledfield>
          </v-col>

          <!-- Postal town -->
          <v-col cols="6">
            <Labelledfield label="Postal Town">
              <v-autocomplete
                :items="supportingFormData.postalTowns"
                item-value="postal_town"
                :item-text="addPostalCodeToPostalTown"
                cache-items
                outlined
                dense
                hide-no-data
                hide-details="auto"
                v-model="userProfileModels.postalTown"
                class="text-field-background"
              ></v-autocomplete>
            </Labelledfield>
          </v-col>

          <!-- Postal address -->
          <v-col cols="6">
            <Labelledfield label="Postal Address">
              <v-text-field
                outlined
                dense
                required
                v-model="userProfileModels.postalAddress"
                class="text-field-background"
              />
            </Labelledfield>
          </v-col>
        </v-row>

        <!-- Disability section -->
        <!-- 
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-radio-group dense v-model="userProfileModels.disabled" row hide-details="auto" class="mt-0"
                                :disabled="true">
                                <template #label><span class="text-body-1">Do you have a disability?</span></template>
                                <v-radio label="Yes" :value="true" color="primary" />
                                <v-radio label="No" :value="false" color="primary" />
                            </v-radio-group>
                        </v-col>

                        <v-col cols="auto">
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-icon color="secondary" v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
                                </template>
                                <span>
                                    Please visit a county office to change your disability status
                                </span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <template v-if="isDisabled">
                            <v-col cols="6">
                                <Labelledfield label="NCPLWD Number" required>
                                    <v-text-field class="text-field-background" outlined dense :rules="[fieldRequired]"
                                        v-model="userProfileModels.ncplwdNumber" hide-details="auto" />
                                </Labelledfield>
                            </v-col>

                            <v-col cols="6">
                                <Labelledfield label="Date of Issuance" required>
                                    <DatePicker :rules="[fieldRequired]" :textFieldProps="{
                                        outlined: true,
                                        dense: true,
                                        class: 'text-field-background',
                                    }" v-model="userProfileModels.ncplwdNumberIssuanceDate" hide-details="auto" />
                                </Labelledfield>
                            </v-col>
                        </template>
                    </v-row>
                -->
        <v-row align="center">
          <v-col cols="auto" class="text-body-2">
            Persons with disability?
          </v-col>

          <v-col cols="auto">
            <v-tooltip top max-width="600">
              <template #activator="{ on, attrs }">
                <v-icon color="secondary" v-on="on" v-bind="attrs"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span>
                For persons with disability, please visit the nearest subcounty
                revenue office with your National Council For Persons Living
                With Disabilities <abbr>(NCPLWD)</abbr> registration details to
                be assisted.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row justify="end">
          <v-col cols="auto">
            <v-btn
              depressed
              class="text-none"
              color="secondary"
              type="submit"
              :loading="updateUserMutatation.isLoading.value"
            >
              Update
              <v-icon right>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </LoadingArea>
  </v-container>
</template>
