var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.$slots.label)?_c('div',{class:{
      '': true,
      'text-caption field-label--text text--lighten-1': _vm.$mobileBreakpoint,
      'text-body-2 black--text': !_vm.$mobileBreakpoint,
    }},[_vm._t("label")],2):_c('div',{class:{
      '': true,
      'text-caption field-label--text text--lighten-1': _vm.$mobileBreakpoint,
      'text-body-2 black--text': !_vm.$mobileBreakpoint,
    }},[_c('span',[_vm._v(_vm._s(_setup.props.label))]),(_vm.required)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(" *"))]):_vm._e()]),_vm._t("default"),(!!_vm.hint)?_c('div',{class:{
      'v-messages theme--light text-field-messages': true,
      'text-caption': _vm.$mobileBreakpoint,
      'black--text': !_vm.$mobileBreakpoint,
    }},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message",domProps:{"innerHTML":_vm._s(_vm.hint)}})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }