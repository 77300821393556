<script setup>
import { getPhoneNumberWithoutCountryCode } from "@/utils/phone_number.js";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { computed, ref, nextTick } from "vue";
import { arrayNotEmpty } from "@/utils/functions";

const props = defineProps({
  value: {
    type: [String, Number],
    default: null,
  },
  countryCode: {
    type: [String],
    default: "KE",
  },
  label: {
    type: [String, Number],
    default: null,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  errorMessages: {
    type: Array,
    default: null
  }
});

const emit = defineEmits(["input"]);

const phoneNumberInput = ref(null);
const errorState = ref(true);
const fieldValid = computed(() => {
  if (errorState.value === true && !arrayNotEmpty(props.errorMessages)) {
    return true;
  }
  return false;
});

const innerValue = computed({
  get() {
    return props.value;
  },
  async set(newVal) {
    emit("input", newVal);
    emit("update:countryCode", phoneNumberInput.value.countryCode);
    await nextTick();
    validate();
  },
});

function validate() {
  for (const rule of props.rules) {
    errorState.value = rule(innerValue.value);
    if (!fieldValid.value) {
      return false;
    }
  }
  return true;
}

defineExpose({
  validate,
});
</script>

<template>
  <div class="phone-number-input">
    <vue-phone-number-input ref="phoneNumberInput" v-model="innerValue" :error="!fieldValid" :default-country-code="'KE'"
      :only-countries="['KE', 'UG']" :translations="{
        phoneNumberLabel: props.label,
      }" no-example />

    <div v-if="!fieldValid" class="v-messages error--text px-3 mb-2 mt-1">
      <template v-if="arrayNotEmpty(errorMessages)">
        {{ errorMessages[0] }}
      </template>
      <template v-else>
        {{ errorState }}
      </template>
    </div>
  </div>
</template>