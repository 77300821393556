import store from '@/store/index.js'
import { reactive, watchEffect, computed, ref, watch } from 'vue'
import { getComputedIsIndividual, getComputedIdOrBusinessText, userProfile, usePostalCodeFromPostalTown, useValidationErrors } from '@/utils/vue_helpers.js'
import { formatDatabasePhoneNumber, getJsDateAsYMD, convertYearsToMs, isValidationError, mapValidationErrorsToObj } from '@/utils/functions.js'
import apiCall from '@/utils/api'
import { useMutation } from '@tanstack/vue-query'
import { getPhoneNumberWithCallingCode } from '@/utils/phone_number'
import { USER_PROFILE } from '@/store/mutations/user'
import { setSnackbar } from '@/components/utils/snackbar/logic'

export function useShowProfileData() {
    const jsNamesToDbNames = {
        customerName: 'customer_name',
        idNumber: 'id_number',
        phoneNumber1: 'phone_number1',
        phoneNumber2: 'phone_number2',
        emailAddress: 'email_address',
        dateOfBirth: 'date_of_birth',
        postalTown: 'postal_town',
        postalAddress: 'postal_address',
        postalCode: 'postal_code',
        disabled: 'disability',
        ncplwdNumber: 'ncplwd_no',
        ncplwdNumberIssuanceDate: 'date_of_issuance',
        genderId: 'gender_id',
        customerTypeId: 'customer_type_id'
    }

    const stringFieldsToConvertToNumbers = ['customerTypeId', 'genderId']

    const userProfileModels = reactive({
        customerName: "",
        idNumber: '',
        customerTypeId: null,
        phoneNumber1: '',
        phoneNumber1CountryCode: "KE",
        phoneNumber2: "",
        phoneNumber2CountryCode: "KE",
        emailAddress: '',
        dateOfBirth: '',
        postalTown: null,
        postalAddress: "",
        disabled: false,
        ncplwdNumber: '',
        ncplwdNumberIssuanceDate: '',
        genderId: null,
    })

    const { addErrorItem, setWatchersForClearOnChange, responseErrorRef, errorItems } = useValidationErrors()
    addErrorItem({
        errorKey: 'email_address',
        clearOnChangeFn: () => userProfileModels.emailAddress
    }
    )
    addErrorItem({
        errorKey: 'phone_number1',
        clearOnChangeFn: () => userProfileModels.phoneNumber1
    })
    addErrorItem({
        errorKey: 'phone_number2',
        clearOnChangeFn: () => userProfileModels.phoneNumber2
    })
    setWatchersForClearOnChange()

    const usingDefaultDateOfBirth = ref(false)
    watch(computed(() => userProfileModels.dateOfBirth), () => {
        usingDefaultDateOfBirth.value = false
    })

    watchEffect(() => {
        const userProfileNotEmpty = userProfile.value !== null
        if (userProfileNotEmpty) {
            setUserProfileModels({ ...userProfile.value })
        }
    })

    function setUserProfileModels(userProfile) {
        convertDbTypesToRequiredTypes(userProfile)
        Object.entries(jsNamesToDbNames).forEach(([jsName, dbName]) => {
            userProfileModels[jsName] = userProfile[dbName]
        })
    }
    function convertDbTypesToRequiredTypes(userProfile) {
        userProfile.customer_type_id = Number(userProfile.customer_type_id)
        userProfile.gender_id = Number(userProfile.gender_id)
        userProfile.phone_number1 = formatDatabasePhoneNumber(userProfile.phone_number1)
        userProfile.phone_number2 = formatDatabasePhoneNumber(userProfile.phone_number2)

        const dateOfBirthEmpty = !(!!userProfile.date_of_birth)
        if (dateOfBirthEmpty) {
            const eighteenYearsAgo = getJsDateAsYMD(new Date(Date.now() - convertYearsToMs(18)))
            userProfile.date_of_birth = eighteenYearsAgo
            usingDefaultDateOfBirth.value = true
        }

        const userDisabled = userProfile.disability === '1'
        if (userDisabled) {
            userProfile.disability = true
        } else {
            userProfile.disability = false
        }
    }
    function convertDisabledModelToBoolean() {
        const userDisabled = userProfileModels.disabled === '1'
        if (userDisabled) {
            userProfileModels.disabled = true
        } else {
            userProfileModels.disabled = false
        }
    }

    const supportingFormData = computed(() => {
        return store.getters.getSupportingFormData;
    });

    const isDisabled = computed(() => {
        return userProfileModels.disabled === true || userProfileModels.disabled === 1;
    });

    const isIndividual = getComputedIsIndividual(() => userProfileModels.customerTypeId)
    const idOrBusinessText = getComputedIdOrBusinessText(() => isIndividual.value)
    const nameLabel = computed(() => {
        if (isIndividual.value) {
            return 'Name'
        }
        return 'Organisation Name'
    })

    const updateUserMutatation = useUpdateUserProfile()

    function getFormattedPhoneNumbers() {
        const phoneNumber1 = getPhoneNumberWithCallingCode(userProfileModels.phoneNumber1, userProfileModels.phoneNumber1CountryCode)
        let phoneNumber2 = null
        if (!!userProfileModels.phoneNumber2) {
            phoneNumber2 = getPhoneNumberWithCallingCode(userProfileModels.phoneNumber2, userProfileModels.phoneNumber2CountryCode)
        }

        return { phoneNumber1, phoneNumber2 }
    }

    const { postalCode } = usePostalCodeFromPostalTown({
        postalTownRef: computed(() => userProfileModels.postalTown)
    })

    function updateUser() {
        const { phoneNumber1, phoneNumber2 } = getFormattedPhoneNumbers()

        const profileData = {
            id_number: userProfileModels.idNumber,
            phone_number1: phoneNumber1,
            phone_number2: phoneNumber2,
            email_address: userProfileModels.emailAddress,
            gender_id: userProfileModels.genderId,
            postal_town: userProfileModels.postalTown,
            postal_code: postalCode.value,
            postal_address: userProfileModels.postalAddress,
            date_of_birth: userProfileModels.dateOfBirth,
        }

        updateUserMutatation.mutate({
            userProfileData: profileData,
        }, {
            onSuccess(data) {
                store.commit(USER_PROFILE, data)
                setSnackbar({
                    text: 'User Profile Updated',
                    color: 'success',
                    open: true
                })
                usingDefaultDateOfBirth.value = false
            },
            onError(error) {
                if (isValidationError(error)) {
                    responseErrorRef.value = error
                    setSnackbar({
                        text: 'Error. Please check your profile details',
                        color: 'error',
                        open: true
                    })
                }
            }
        })
    }

    return {
        userProfileModels, supportingFormData, nameLabel, isIndividual, idOrBusinessText,
        postalCode, isDisabled, updateUser, usingDefaultDateOfBirth, updateUserMutatation,
        errorItems
    }
}

export function useUpdateUserProfile() {
    async function updateUserProfile({ userProfileData }) {
        try {
            const response = await apiCall({
                url: '/api/client/account/update-logged-in-account',
                data: userProfileData,
                method: 'PUT'
            })
            const userProfile = response.profile
            return userProfile
        } catch (error) {
            throw (error)
        }
    }

    const updateUserMutation = useMutation({
        mutationFn: updateUserProfile
    })

    return updateUserMutation
}